/**
 * 页面静止一段时间后执行回调函数
 * @param callback
 * @param time 默认15秒
 * @returns
 */

export function usePageSlientTime(callback: () => void, time: number = 15 * 1000): any {
    if (!callback) return console.warn("callback is undefined");
    // let scrollY = 0;
    const countDownInstance = countDown(time, () => {
        cancel();
        callback();
    });

    onMounted(() => {
        start();
    });

    onUnmounted(() => {
        cancel();
    });

    /** 滚动条滚动的时候重置倒计时 */
    // function scrollFnc() {
    //     countDownInstance.reset();
    // }

    /** 滚动条滚动的时候记录滚动位置 */
    // function scrollFnc() {
    //     scrollY = window.scrollY;
    //     return scrollY;
    // }

    /** 监听滚动条的滚动,并开始倒计时 */
    function start() {
        countDownInstance.start();
        // window.addEventListener("scroll", scrollFnc, true);
    }

    /** 移除监听滚动条的滚动,并移除倒计时 */
    function cancel() {
        // window.removeEventListener("scroll", scrollFnc, true);
        countDownInstance.stop();
    }

    /** 重新开始 */
    function restart() {
        cancel();
        start();
    }

    return {
        restart,
        cancel,
    };
}

/**
 * 倒计时
 * @param delay
 * @param callback
 * @returns {start, stop, reset}
 */
function countDown(delay: number, callback: () => void) {
    let timer: any = null;

    /** 开始 */
    function start() {
        if (!timer) {
            timer = setTimeout(() => {
                callback();
            }, delay);
        }
    }

    /** 取消 */
    function stop() {
        clearTimeout(timer);
        timer = null;
    }

    /** 重置 */
    function reset() {
        stop();
        start();
    }

    return {
        start,
        stop,
        reset,
    };
}
