import type { NuxtApp } from "#app";

export const usePageHead = async () => {
    const route = useRoute();

    const { locale, locales, localeProperties } = useI18n();

    const { data: td } = await useAsyncData(`title-description_${route.path}`, async (nuxtApp?: NuxtApp) => {
        const result = await useStrapiWithLocale<Td.Instance>("/api/db?name=title-description");
        try {
            const local = nuxtApp?.$config.public.local;
            (result as any).local = local;
        } catch (error) {}
        return result;
    });

    useHead(() => {
        const obj = td.value?.filter((item: any) => item.path === route.path)[0];
        const tdObj = Object.assign(
            {
                path: "/",
                title: "NearHub | Smart Board and Whiteboard Software for Collaboration",
                description: "NearHub provides the all-in-one interactive whiteboard solution. Enable teamwork and teaching anywhere, anytime",
                image: "https://aw-public-abroad.oss-us-west-1.aliyuncs.com/nearhub-v1/home/smart-whiteboard.mp4",
            },
            obj
        );
        let omitLocalePath = "";
        if (!["/", "", "/de", "/ja-JP", "/es"].includes(route.path)) {
            omitLocalePath = route.path.replace(`${locale.value}/`, "");
        }

        const links = [
            { rel: "canonical", href: `https://www.nearhub.us${route.path === "/" ? "" : route.path}` },
            ...locales.value
                .filter((item: any) => item.code !== locale.value && item.code !== "es")
                .map((item: any) => {
                    if (item.code === "en") {
                        return {
                            rel: "alternate",
                            hreflang: item.code,
                            href: `https://www.nearhub.us${omitLocalePath}`,
                        };
                    }
                    return {
                        rel: "alternate",
                        hreflang: item.code,
                        href: `https://www.nearhub.us/${item.code}${omitLocalePath}`,
                    };
                }),
        ];
        links.push(
            ...[
                {
                    rel: "alternate",
                    hreflang: locale.value,
                    href: `https://www.nearhub.us${route.path === "/" ? "" : route.path}`,
                },
                {
                    rel: "alternate",
                    hreflang: "x-default",
                    href: `https://www.nearhub.us${omitLocalePath}`,
                },
            ]
        );
        const meta = [
            // <!-- Primary Meta Tags -->
            { name: "description", content: tdObj?.description },
            { property: "og:title", content: tdObj?.description },

            /**<!-- Twitter --> */
            { property: "twitter:title", content: tdObj?.title },
            { property: "twitter:description", content: tdObj?.description },
            { property: "twitter:url", content: `https://www.nearhub.us${route.path}` },
            { property: "twitter:card", content: "summary_large_image" },
            { property: "twitter:image", content: tdObj?.image || "" },
            { property: "twitter:image:alt", content: tdObj?.title },

            /**<!-- Open Graph / Facebook --> */
            { property: "og:title", content: tdObj?.title },
            { property: "og:description", content: tdObj?.description },
            { property: "og:url", content: `https://www.nearhub.us${route.path}` },
            { property: "og:type", content: "website" },
            // { property: "og:site_name", content: "NearHub Blog" },
            { property: "og:image", content: tdObj?.image || "" },
            { property: "og:image:alt", content: tdObj?.title },
        ];
        // 如果是存在 ?srsltid=${value}的 则加入noindex
        if (route.query.srsltid) {
            meta.push({ name: "robots", content: "noindex" });
        }
        return {
            htmlAttrs: {
                lang: localeProperties.value.code,
            },
            title: tdObj?.title,
            meta: route.path.includes("/blog/") ? [] : meta,
            link: links,
        };
    });
};
