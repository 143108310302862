import gsap from "gsap";
import type { Ref } from "vue";

export const useDrawerOpen = () => {
    const domRef = ref<HTMLElement>();
    let animation: gsap.core.Tween | null;
    const active = ref(false);
    const open = () => {
        if (!domRef.value) throw Error("缺少dom节点");
        active.value = true;
        const top = document.querySelector(".app-topmost")?.clientHeight || 0 + "px";
        animation = gsap.fromTo(domRef.value, { display: "none", left: "100%", top }, { display: "block", left: "0%", top, duration: 0.3 });
    };

    const close = () => {
        if (animation) {
            animation.reverse();
            animation = null;
            active.value = false;
        } else {
            console.warn("没有可执行动画");
        }
    };

    return [
        domRef,
        {
            active,
            open,
            close,
        },
    ] as const;
};

export const usePreventBodyScroll = (referenceRef: Ref<boolean>) => {
    if (!process.client) return;
    let isReady = false;

    function handleActive(body = document.body) {
        // is server render or dom is not mounted
        if (!isReady) return;
        // is in actived
        if (body.style.overflow === "hidden") return;

        body.setAttribute("data-overflow", body.style.overflow);
        body.style.overflow = "hidden";
    }
    function handleDestory(body = document.body) {
        const value = body.getAttribute("data-overflow");
        body.style.overflow = value || "";
        body.removeAttribute("data-overflow");
    }

    watch(
        () => referenceRef.value,
        (value) => {
            value ? handleActive() : handleDestory();
        }
    );

    onMounted(() => {
        isReady = true;
        if (referenceRef.value) handleActive();
    });

    onUnmounted(() => {
        handleDestory();
    });
};
