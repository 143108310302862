/*
 * @Author: lich
 * @Date: 2023-04-03 13:16:47
 * @Last Modified by: lich
 * @Last Modified time: 2023-04-03 13:20:37
 * @Description: 用于多语言的切换时，组件重新渲染
 */

export const useRenderWithLocale = () => {
    const { locale } = useI18n();
    const render = ref(true);
    watch(locale, () => {
        render.value = false;
        nextTick(() => {
            render.value = true;
        });
    });

    return { renderWithLocale: render };
};
